
import { computed, defineComponent } from "vue";
import ConversionService from "@/services/ConversionService";
import {
  ChangeDetectionTaskReport,
  ImportRunReport,
} from "../../generated/api/src";

export default defineComponent({
  name: "DifferenzanalyseTable",
  props: {
    importRun: {
      type: Object as () => ImportRunReport,
      required: true,
    },
  },
  setup(props) {
    return {
      changeDetectionTaskReports: computed(() =>
        props.importRun.changeDetectionTaskReports?.map(
          (task: ChangeDetectionTaskReport) => ({
            name: ConversionService.translate(
              task.name?.replace("CHANGE_DETECTOR_", "")
            ),
            newCount: task.newCount && task.newCount > 0 ? task.newCount : 0,
            updatedCount:
              task.updatedCount && task.updatedCount > 0
                ? task.updatedCount
                : 0,
            existingNotUpdatedCount:
              task.existingNotUpdatedCount && task.existingNotUpdatedCount > 0
                ? task.existingNotUpdatedCount
                : 0,
            existingRecheckCount:
              task.existingRecheckCount && task.existingRecheckCount > 0
                ? task.existingRecheckCount
                : 0,
            startedAt: ConversionService.formatTime(task.startedAt),
            endedAt: ConversionService.formatTime(task.endedAt),
            errorCode: task.errorCode === 0 ? "-" : task.errorCode,
            status: ConversionService.translateStatus(task.status),
          })
        )
      ),
      formatNumber: ConversionService.formatNumber,
    };
  },
});
