import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createBlock(_component_el_button, {
    type: "primary",
    onClick: _ctx.reloadImportRunReports
  }, {
    default: _withCtx(() => [
      _createTextVNode(" Aktualisieren ")
    ]),
    _: 1
  }, 8, ["onClick"]))
}