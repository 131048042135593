
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "ReloadButton",
  setup() {
    const store = useStore();

    function reloadImportRunReports(): void {
      store.dispatch("getAllImports");
    }
    return {
      reloadImportRunReports,
    };
  },
});
