/* tslint:disable */
/* eslint-disable */
/**
 * IamDiViSImporterApi
 * API des Services für die Datenübertragung aus DiViS zum UCS IAM
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApplicationConfiguration
 */
export interface ApplicationConfiguration {
    /**
     * 
     * @type {string}
     * @memberof ApplicationConfiguration
     */
    ucsUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationConfiguration
     */
    testing_enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationConfiguration
     */
    idiVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationConfiguration
     */
    idiVersionCreatedAt?: string;
}
/**
 * 
 * @export
 * @interface ChangeDetectionTaskReport
 */
export interface ChangeDetectionTaskReport {
    /**
     * 
     * @type {string}
     * @memberof ChangeDetectionTaskReport
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeDetectionTaskReport
     */
    name?: string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof ChangeDetectionTaskReport
     */
    status?: TaskStatus;
    /**
     * 
     * @type {number}
     * @memberof ChangeDetectionTaskReport
     */
    newCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeDetectionTaskReport
     */
    updatedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeDetectionTaskReport
     */
    existingNotUpdatedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeDetectionTaskReport
     */
    existingRecheckCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ChangeDetectionTaskReport
     */
    startedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeDetectionTaskReport
     */
    endedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ChangeDetectionTaskReport
     */
    errorCode?: number;
}
/**
 * 
 * @export
 * @interface DatasetEnforcement
 */
export interface DatasetEnforcement {
    /**
     * 
     * @type {Array<string>}
     * @memberof DatasetEnforcement
     */
    classes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatasetEnforcement
     */
    offers?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatasetEnforcement
     */
    students?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatasetEnforcement
     */
    teachers?: Array<string>;
}
/**
 * 
 * @export
 * @interface ErrorCodeAndCount
 */
export interface ErrorCodeAndCount {
    /**
     * 
     * @type {string}
     * @memberof ErrorCodeAndCount
     */
    level: string;
    /**
     * 
     * @type {number}
     * @memberof ErrorCodeAndCount
     */
    messageCode: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorCodeAndCount
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorCodeAndCount
     */
    ucsDataContext: string;
    /**
     * 
     * @type {number}
     * @memberof ErrorCodeAndCount
     */
    count: number;
}
/**
 * 
 * @export
 * @interface ExportTaskReport
 */
export interface ExportTaskReport {
    /**
     * 
     * @type {string}
     * @memberof ExportTaskReport
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportTaskReport
     */
    name?: string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof ExportTaskReport
     */
    status?: TaskStatus;
    /**
     * 
     * @type {number}
     * @memberof ExportTaskReport
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ExportTaskReport
     */
    processedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ExportTaskReport
     */
    failedCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ExportTaskReport
     */
    startedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportTaskReport
     */
    endedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExportTaskReport
     */
    errorCode?: number;
}
/**
 * 
 * @export
 * @interface FileAvailabilityInfo
 */
export interface FileAvailabilityInfo {
    /**
     * 
     * @type {string}
     * @memberof FileAvailabilityInfo
     */
    filePath: string;
    /**
     * 
     * @type {boolean}
     * @memberof FileAvailabilityInfo
     */
    available: boolean;
    /**
     * 
     * @type {string}
     * @memberof FileAvailabilityInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof FileAvailabilityInfo
     */
    fileSize?: string;
    /**
     * 
     * @type {string}
     * @memberof FileAvailabilityInfo
     */
    fileType: FileAvailabilityInfoFileTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FileAvailabilityInfoFileTypeEnum {
    CSV = 'CSV',
    REPORT_OVERVIEW = 'REPORT_OVERVIEW',
    ERROR_REPORT = 'ERROR_REPORT',
    IDI_STATE_REPORT = 'IDI_STATE_REPORT',
    UNKNOWN = 'UNKNOWN'
}

/**
 * 
 * @export
 * @interface ImportRunLog
 */
export interface ImportRunLog {
    /**
     * 
     * @type {string}
     * @memberof ImportRunLog
     */
    level: string;
    /**
     * 
     * @type {string}
     * @memberof ImportRunLog
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof ImportRunLog
     */
    context: string;
    /**
     * 
     * @type {string}
     * @memberof ImportRunLog
     */
    occurredAt: string;
    /**
     * 
     * @type {number}
     * @memberof ImportRunLog
     */
    messageCode: number;
    /**
     * 
     * @type {string}
     * @memberof ImportRunLog
     */
    messageType?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportRunLog
     */
    divisId?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportRunLog
     */
    iamUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportRunLog
     */
    ucsDataContext: string;
    /**
     * 
     * @type {string}
     * @memberof ImportRunLog
     */
    errorDetails?: string;
}
/**
 * 
 * @export
 * @interface ImportRunReport
 */
export interface ImportRunReport {
    /**
     * 
     * @type {number}
     * @memberof ImportRunReport
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ImportRunReport
     */
    startedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportRunReport
     */
    endedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportRunReport
     */
    idiVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportRunReport
     */
    idiVersionCreatedAt?: string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof ImportRunReport
     */
    status?: TaskStatus;
    /**
     * 
     * @type {Array<ImportTaskReport>}
     * @memberof ImportRunReport
     */
    importTaskReports?: Array<ImportTaskReport>;
    /**
     * 
     * @type {Array<ChangeDetectionTaskReport>}
     * @memberof ImportRunReport
     */
    changeDetectionTaskReports?: Array<ChangeDetectionTaskReport>;
    /**
     * 
     * @type {Array<ExportTaskReport>}
     * @memberof ImportRunReport
     */
    exportTaskReports?: Array<ExportTaskReport>;
}
/**
 * 
 * @export
 * @interface ImportTaskReport
 */
export interface ImportTaskReport {
    /**
     * 
     * @type {string}
     * @memberof ImportTaskReport
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportTaskReport
     */
    name?: string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof ImportTaskReport
     */
    status?: TaskStatus;
    /**
     * 
     * @type {number}
     * @memberof ImportTaskReport
     */
    recordCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ImportTaskReport
     */
    recordFilteredCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ImportTaskReport
     */
    recordDistinctCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ImportTaskReport
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportTaskReport
     */
    startedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportTaskReport
     */
    endedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ImportTaskReport
     */
    errorCode?: number;
}
/**
 * 
 * @export
 * @interface LogFilter
 */
export interface LogFilter {
    /**
     * 
     * @type {string}
     * @memberof LogFilter
     */
    logLevel?: string;
    /**
     * 
     * @type {string}
     * @memberof LogFilter
     */
    logContext?: string;
    /**
     * 
     * @type {string}
     * @memberof LogFilter
     */
    ucsDataContext?: string;
    /**
     * 
     * @type {number}
     * @memberof LogFilter
     */
    messageCode?: number;
    /**
     * 
     * @type {string}
     * @memberof LogFilter
     */
    messageType?: string;
    /**
     * 
     * @type {string}
     * @memberof LogFilter
     */
    divisId?: string;
    /**
     * 
     * @type {string}
     * @memberof LogFilter
     */
    iamUid?: string;
    /**
     * 
     * @type {string}
     * @memberof LogFilter
     */
    dateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof LogFilter
     */
    dateTo?: string;
}
/**
 * 
 * @export
 * @interface LogPagination
 */
export interface LogPagination {
    /**
     * 
     * @type {number}
     * @memberof LogPagination
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof LogPagination
     */
    pageSize?: number;
}
/**
 * 
 * @export
 * @interface LogSearchParams
 */
export interface LogSearchParams {
    /**
     * 
     * @type {LogFilter}
     * @memberof LogSearchParams
     */
    logFilter: LogFilter;
    /**
     * 
     * @type {LogPagination}
     * @memberof LogSearchParams
     */
    pagination: LogPagination;
    /**
     * 
     * @type {LogSorting}
     * @memberof LogSearchParams
     */
    sort: LogSorting;
}
/**
 * 
 * @export
 * @interface LogSorting
 */
export interface LogSorting {
    /**
     * 
     * @type {string}
     * @memberof LogSorting
     */
    attributeName?: string;
    /**
     * 
     * @type {string}
     * @memberof LogSorting
     */
    direction?: LogSortingDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum LogSortingDirectionEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}

/**
 * 
 * @export
 * @interface MessageOccurrence
 */
export interface MessageOccurrence {
    /**
     * 
     * @type {string}
     * @memberof MessageOccurrence
     */
    divisId: string;
    /**
     * 
     * @type {string}
     * @memberof MessageOccurrence
     */
    iamUid: string;
    /**
     * 
     * @type {string}
     * @memberof MessageOccurrence
     */
    occurredAt: string;
    /**
     * 
     * @type {string}
     * @memberof MessageOccurrence
     */
    message: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum TaskStatus {
    SUCCESSFUL = 'SUCCESSFUL',
    RUNNING = 'RUNNING',
    FAILED = 'FAILED',
    ABORTED = 'ABORTED',
    FINISHED = 'FINISHED'
}


/**
 * ConfigApi - axios parameter creator
 * @export
 */
export const ConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfig: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigApi - functional programming interface
 * @export
 */
export const ConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfig(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigApi - factory interface
 * @export
 */
export const ConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfig(options?: any): AxiosPromise<ApplicationConfiguration> {
            return localVarFp.getConfig(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigApi - interface
 * @export
 * @interface ConfigApi
 */
export interface ConfigApiInterface {
    /**
     * 
     * @summary Retrieves config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApiInterface
     */
    getConfig(options?: any): AxiosPromise<ApplicationConfiguration>;

}

/**
 * ConfigApi - object-oriented interface
 * @export
 * @class ConfigApi
 * @extends {BaseAPI}
 */
export class ConfigApi extends BaseAPI implements ConfigApiInterface {
    /**
     * 
     * @summary Retrieves config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public getConfig(options?: any) {
        return ConfigApiFp(this.configuration).getConfig(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GeneratorApi - axios parameter creator
 * @export
 */
export const GeneratorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes all data in the test data directory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestdata: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/generator/testdata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exports the given step of the testdata set as Divis-like csv files
         * @summary Export testdata
         * @param {number} step 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportTestdata: async (step: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'step' is not null or undefined
            assertParamExists('exportTestdata', 'step', step)
            const localVarPath = `/api/v1/generator/testdata/{step}/export`
                .replace(`{${"step"}}`, encodeURIComponent(String(step)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generates a new test data set
         * @summary Trigger testdata generation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTestdata: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/generator/testdata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneratorApi - functional programming interface
 * @export
 */
export const GeneratorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneratorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes all data in the test data directory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTestdata(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTestdata(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exports the given step of the testdata set as Divis-like csv files
         * @summary Export testdata
         * @param {number} step 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportTestdata(step: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportTestdata(step, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generates a new test data set
         * @summary Trigger testdata generation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTestdata(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTestdata(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeneratorApi - factory interface
 * @export
 */
export const GeneratorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneratorApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes all data in the test data directory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestdata(options?: any): AxiosPromise<void> {
            return localVarFp.deleteTestdata(options).then((request) => request(axios, basePath));
        },
        /**
         * Exports the given step of the testdata set as Divis-like csv files
         * @summary Export testdata
         * @param {number} step 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportTestdata(step: number, options?: any): AxiosPromise<void> {
            return localVarFp.exportTestdata(step, options).then((request) => request(axios, basePath));
        },
        /**
         * Generates a new test data set
         * @summary Trigger testdata generation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTestdata(options?: any): AxiosPromise<void> {
            return localVarFp.generateTestdata(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeneratorApi - interface
 * @export
 * @interface GeneratorApi
 */
export interface GeneratorApiInterface {
    /**
     * 
     * @summary Deletes all data in the test data directory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneratorApiInterface
     */
    deleteTestdata(options?: any): AxiosPromise<void>;

    /**
     * Exports the given step of the testdata set as Divis-like csv files
     * @summary Export testdata
     * @param {number} step 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneratorApiInterface
     */
    exportTestdata(step: number, options?: any): AxiosPromise<void>;

    /**
     * Generates a new test data set
     * @summary Trigger testdata generation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneratorApiInterface
     */
    generateTestdata(options?: any): AxiosPromise<void>;

}

/**
 * GeneratorApi - object-oriented interface
 * @export
 * @class GeneratorApi
 * @extends {BaseAPI}
 */
export class GeneratorApi extends BaseAPI implements GeneratorApiInterface {
    /**
     * 
     * @summary Deletes all data in the test data directory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneratorApi
     */
    public deleteTestdata(options?: any) {
        return GeneratorApiFp(this.configuration).deleteTestdata(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exports the given step of the testdata set as Divis-like csv files
     * @summary Export testdata
     * @param {number} step 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneratorApi
     */
    public exportTestdata(step: number, options?: any) {
        return GeneratorApiFp(this.configuration).exportTestdata(step, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generates a new test data set
     * @summary Trigger testdata generation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneratorApi
     */
    public generateTestdata(options?: any) {
        return GeneratorApiFp(this.configuration).generateTestdata(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImportApi - axios parameter creator
 * @export
 */
export const ImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Check if csv- & report files of an import-run are available for download
         * @summary Check for csv- & reportfiles of an import-run
         * @param {number} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIfFilesAreAvailableForDownload: async (importId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'importId' is not null or undefined
            assertParamExists('checkIfFilesAreAvailableForDownload', 'importId', importId)
            const localVarPath = `/api/v1/import/{importId}/file/availability`
                .replace(`{${"importId"}}`, encodeURIComponent(String(importId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download a specified csv-file
         * @summary Download csv-file
         * @param {number} importId 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCsvFile: async (importId: number, filename: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'importId' is not null or undefined
            assertParamExists('downloadCsvFile', 'importId', importId)
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('downloadCsvFile', 'filename', filename)
            const localVarPath = `/api/v1/import/{importId}/file/csv/{filename}`
                .replace(`{${"importId"}}`, encodeURIComponent(String(importId)))
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download a specified file
         * @summary Download file
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile: async (filename: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('downloadFile', 'filename', filename)
            const localVarPath = `/api/v1/import/folder/{filename}`
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download state report file
         * @summary Download state report file
         * @param {number} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadIdiStateReportFile: async (importId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'importId' is not null or undefined
            assertParamExists('downloadIdiStateReportFile', 'importId', importId)
            const localVarPath = `/api/v1/import/{importId}/file/report/state`
                .replace(`{${"importId"}}`, encodeURIComponent(String(importId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download the report error file of an importRun
         * @summary Download report error file
         * @param {number} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportErrorFile: async (importId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'importId' is not null or undefined
            assertParamExists('downloadReportErrorFile', 'importId', importId)
            const localVarPath = `/api/v1/import/{importId}/file/report/error`
                .replace(`{${"importId"}}`, encodeURIComponent(String(importId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download the report overview file of an importRun
         * @summary Download report overview file
         * @param {number} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportOverviewFile: async (importId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'importId' is not null or undefined
            assertParamExists('downloadReportOverviewFile', 'importId', importId)
            const localVarPath = `/api/v1/import/{importId}/file/report/overview`
                .replace(`{${"importId"}}`, encodeURIComponent(String(importId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of all dataset-enforcements
         * @summary Get current dataset-enforcements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDatasetEnforcement: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/import/dataset-enforcement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all files of import-folder
         * @summary List all files of import-folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllImportFolderFiles: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/import/folder/files/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the import run related business logs
         * @summary Get business logs for import run id
         * @param {number} importId 
         * @param {'DEBUG' | 'INFO' | 'WARN' | 'ERROR'} [excludedLevel] Log-Level, dessen Messages nicht im Ergebnis enthalten sein sollen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllImportLogs: async (importId: number, excludedLevel?: 'DEBUG' | 'INFO' | 'WARN' | 'ERROR', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'importId' is not null or undefined
            assertParamExists('getAllImportLogs', 'importId', importId)
            const localVarPath = `/api/v1/import/{importId}/logs/all`
                .replace(`{${"importId"}}`, encodeURIComponent(String(importId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (excludedLevel !== undefined) {
                localVarQueryParameter['excludedLevel'] = excludedLevel;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of all imports
         * @summary Get all imports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllImports: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns which error codes occured and how often they occured during the import
         * @summary Get errorcodes and number of their occurances for import run id
         * @param {number} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getErrorCodeCounts: async (importId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'importId' is not null or undefined
            assertParamExists('getErrorCodeCounts', 'importId', importId)
            const localVarPath = `/api/v1/import/{importId}/errorcodes`
                .replace(`{${"importId"}}`, encodeURIComponent(String(importId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the import run report for given id
         * @summary Get the import run report details for id
         * @param {number} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImport: async (importId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'importId' is not null or undefined
            assertParamExists('getImport', 'importId', importId)
            const localVarPath = `/api/v1/import/{importId}`
                .replace(`{${"importId"}}`, encodeURIComponent(String(importId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the import run related business logs for the page number
         * @summary Get business logs for import run id and page
         * @param {number} importId 
         * @param {LogSearchParams} [logSearchParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImportLogs: async (importId: number, logSearchParams?: LogSearchParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'importId' is not null or undefined
            assertParamExists('getImportLogs', 'importId', importId)
            const localVarPath = `/api/v1/import/{importId}/logs`
                .replace(`{${"importId"}}`, encodeURIComponent(String(importId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logSearchParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the number of run related business logs
         * @summary Get count of logs for import run id
         * @param {number} importId 
         * @param {LogFilter} [logFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImportLogsCount: async (importId: number, logFilter?: LogFilter, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'importId' is not null or undefined
            assertParamExists('getImportLogsCount', 'importId', importId)
            const localVarPath = `/api/v1/import/{importId}/logs/count`
                .replace(`{${"importId"}}`, encodeURIComponent(String(importId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the Divis-IDs/IAM-UIDs of all records where messages of a specified type occurred within an import.
         * @summary Get all occurrences of a specified messagetype for one import run
         * @param {number} importId 
         * @param {string} messagetype 
         * @param {string} ucsDataContext 
         * @param {Array<'WARN' | 'ERROR'>} level Log-Level, dessen Messages im Ergebnis enthalten sein sollen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageOccurrences: async (importId: number, messagetype: string, ucsDataContext: string, level: Array<'WARN' | 'ERROR'>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'importId' is not null or undefined
            assertParamExists('getMessageOccurrences', 'importId', importId)
            // verify required parameter 'messagetype' is not null or undefined
            assertParamExists('getMessageOccurrences', 'messagetype', messagetype)
            // verify required parameter 'ucsDataContext' is not null or undefined
            assertParamExists('getMessageOccurrences', 'ucsDataContext', ucsDataContext)
            // verify required parameter 'level' is not null or undefined
            assertParamExists('getMessageOccurrences', 'level', level)
            const localVarPath = `/api/v1/import/{importId}/message/occurrences`
                .replace(`{${"importId"}}`, encodeURIComponent(String(importId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (messagetype !== undefined) {
                localVarQueryParameter['messagetype'] = messagetype;
            }

            if (ucsDataContext !== undefined) {
                localVarQueryParameter['ucsDataContext'] = ucsDataContext;
            }

            if (level) {
                localVarQueryParameter['level'] = level;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * null
         * @summary Set dataset-enforcements list a new import manually
         * @param {DatasetEnforcement} [datasetEnforcement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDatasetEnforcements: async (datasetEnforcement?: DatasetEnforcement, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/import/dataset-enforcement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetEnforcement, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Triggers a new import manually if process is not already running
         * @summary Trigger a new import manually
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerImportManually: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImportApi - functional programming interface
 * @export
 */
export const ImportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImportApiAxiosParamCreator(configuration)
    return {
        /**
         * Check if csv- & report files of an import-run are available for download
         * @summary Check for csv- & reportfiles of an import-run
         * @param {number} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkIfFilesAreAvailableForDownload(importId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileAvailabilityInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkIfFilesAreAvailableForDownload(importId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Download a specified csv-file
         * @summary Download csv-file
         * @param {number} importId 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadCsvFile(importId: number, filename: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadCsvFile(importId, filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Download a specified file
         * @summary Download file
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFile(filename: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFile(filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Download state report file
         * @summary Download state report file
         * @param {number} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadIdiStateReportFile(importId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadIdiStateReportFile(importId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Download the report error file of an importRun
         * @summary Download report error file
         * @param {number} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadReportErrorFile(importId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadReportErrorFile(importId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Download the report overview file of an importRun
         * @summary Download report overview file
         * @param {number} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadReportOverviewFile(importId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadReportOverviewFile(importId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of all dataset-enforcements
         * @summary Get current dataset-enforcements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDatasetEnforcement(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetEnforcement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDatasetEnforcement(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all files of import-folder
         * @summary List all files of import-folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllImportFolderFiles(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileAvailabilityInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllImportFolderFiles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the import run related business logs
         * @summary Get business logs for import run id
         * @param {number} importId 
         * @param {'DEBUG' | 'INFO' | 'WARN' | 'ERROR'} [excludedLevel] Log-Level, dessen Messages nicht im Ergebnis enthalten sein sollen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllImportLogs(importId: number, excludedLevel?: 'DEBUG' | 'INFO' | 'WARN' | 'ERROR', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImportRunLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllImportLogs(importId, excludedLevel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of all imports
         * @summary Get all imports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllImports(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImportRunReport>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllImports(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns which error codes occured and how often they occured during the import
         * @summary Get errorcodes and number of their occurances for import run id
         * @param {number} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getErrorCodeCounts(importId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ErrorCodeAndCount>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getErrorCodeCounts(importId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the import run report for given id
         * @summary Get the import run report details for id
         * @param {number} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImport(importId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportRunReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImport(importId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the import run related business logs for the page number
         * @summary Get business logs for import run id and page
         * @param {number} importId 
         * @param {LogSearchParams} [logSearchParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImportLogs(importId: number, logSearchParams?: LogSearchParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImportRunLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImportLogs(importId, logSearchParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the number of run related business logs
         * @summary Get count of logs for import run id
         * @param {number} importId 
         * @param {LogFilter} [logFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImportLogsCount(importId: number, logFilter?: LogFilter, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImportLogsCount(importId, logFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the Divis-IDs/IAM-UIDs of all records where messages of a specified type occurred within an import.
         * @summary Get all occurrences of a specified messagetype for one import run
         * @param {number} importId 
         * @param {string} messagetype 
         * @param {string} ucsDataContext 
         * @param {Array<'WARN' | 'ERROR'>} level Log-Level, dessen Messages im Ergebnis enthalten sein sollen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageOccurrences(importId: number, messagetype: string, ucsDataContext: string, level: Array<'WARN' | 'ERROR'>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MessageOccurrence>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageOccurrences(importId, messagetype, ucsDataContext, level, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * null
         * @summary Set dataset-enforcements list a new import manually
         * @param {DatasetEnforcement} [datasetEnforcement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDatasetEnforcements(datasetEnforcement?: DatasetEnforcement, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDatasetEnforcements(datasetEnforcement, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Triggers a new import manually if process is not already running
         * @summary Trigger a new import manually
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerImportManually(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerImportManually(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImportApi - factory interface
 * @export
 */
export const ImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImportApiFp(configuration)
    return {
        /**
         * Check if csv- & report files of an import-run are available for download
         * @summary Check for csv- & reportfiles of an import-run
         * @param {number} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIfFilesAreAvailableForDownload(importId: number, options?: any): AxiosPromise<Array<FileAvailabilityInfo>> {
            return localVarFp.checkIfFilesAreAvailableForDownload(importId, options).then((request) => request(axios, basePath));
        },
        /**
         * Download a specified csv-file
         * @summary Download csv-file
         * @param {number} importId 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCsvFile(importId: number, filename: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadCsvFile(importId, filename, options).then((request) => request(axios, basePath));
        },
        /**
         * Download a specified file
         * @summary Download file
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile(filename: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadFile(filename, options).then((request) => request(axios, basePath));
        },
        /**
         * Download state report file
         * @summary Download state report file
         * @param {number} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadIdiStateReportFile(importId: number, options?: any): AxiosPromise<any> {
            return localVarFp.downloadIdiStateReportFile(importId, options).then((request) => request(axios, basePath));
        },
        /**
         * Download the report error file of an importRun
         * @summary Download report error file
         * @param {number} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportErrorFile(importId: number, options?: any): AxiosPromise<any> {
            return localVarFp.downloadReportErrorFile(importId, options).then((request) => request(axios, basePath));
        },
        /**
         * Download the report overview file of an importRun
         * @summary Download report overview file
         * @param {number} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportOverviewFile(importId: number, options?: any): AxiosPromise<any> {
            return localVarFp.downloadReportOverviewFile(importId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of all dataset-enforcements
         * @summary Get current dataset-enforcements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDatasetEnforcement(options?: any): AxiosPromise<DatasetEnforcement> {
            return localVarFp.getAllDatasetEnforcement(options).then((request) => request(axios, basePath));
        },
        /**
         * List all files of import-folder
         * @summary List all files of import-folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllImportFolderFiles(options?: any): AxiosPromise<Array<FileAvailabilityInfo>> {
            return localVarFp.getAllImportFolderFiles(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the import run related business logs
         * @summary Get business logs for import run id
         * @param {number} importId 
         * @param {'DEBUG' | 'INFO' | 'WARN' | 'ERROR'} [excludedLevel] Log-Level, dessen Messages nicht im Ergebnis enthalten sein sollen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllImportLogs(importId: number, excludedLevel?: 'DEBUG' | 'INFO' | 'WARN' | 'ERROR', options?: any): AxiosPromise<Array<ImportRunLog>> {
            return localVarFp.getAllImportLogs(importId, excludedLevel, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of all imports
         * @summary Get all imports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllImports(options?: any): AxiosPromise<Array<ImportRunReport>> {
            return localVarFp.getAllImports(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns which error codes occured and how often they occured during the import
         * @summary Get errorcodes and number of their occurances for import run id
         * @param {number} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getErrorCodeCounts(importId: number, options?: any): AxiosPromise<Array<ErrorCodeAndCount>> {
            return localVarFp.getErrorCodeCounts(importId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the import run report for given id
         * @summary Get the import run report details for id
         * @param {number} importId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImport(importId: number, options?: any): AxiosPromise<ImportRunReport> {
            return localVarFp.getImport(importId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the import run related business logs for the page number
         * @summary Get business logs for import run id and page
         * @param {number} importId 
         * @param {LogSearchParams} [logSearchParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImportLogs(importId: number, logSearchParams?: LogSearchParams, options?: any): AxiosPromise<Array<ImportRunLog>> {
            return localVarFp.getImportLogs(importId, logSearchParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the number of run related business logs
         * @summary Get count of logs for import run id
         * @param {number} importId 
         * @param {LogFilter} [logFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImportLogsCount(importId: number, logFilter?: LogFilter, options?: any): AxiosPromise<number> {
            return localVarFp.getImportLogsCount(importId, logFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the Divis-IDs/IAM-UIDs of all records where messages of a specified type occurred within an import.
         * @summary Get all occurrences of a specified messagetype for one import run
         * @param {number} importId 
         * @param {string} messagetype 
         * @param {string} ucsDataContext 
         * @param {Array<'WARN' | 'ERROR'>} level Log-Level, dessen Messages im Ergebnis enthalten sein sollen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageOccurrences(importId: number, messagetype: string, ucsDataContext: string, level: Array<'WARN' | 'ERROR'>, options?: any): AxiosPromise<Array<MessageOccurrence>> {
            return localVarFp.getMessageOccurrences(importId, messagetype, ucsDataContext, level, options).then((request) => request(axios, basePath));
        },
        /**
         * null
         * @summary Set dataset-enforcements list a new import manually
         * @param {DatasetEnforcement} [datasetEnforcement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDatasetEnforcements(datasetEnforcement?: DatasetEnforcement, options?: any): AxiosPromise<void> {
            return localVarFp.setDatasetEnforcements(datasetEnforcement, options).then((request) => request(axios, basePath));
        },
        /**
         * Triggers a new import manually if process is not already running
         * @summary Trigger a new import manually
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerImportManually(options?: any): AxiosPromise<number> {
            return localVarFp.triggerImportManually(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImportApi - interface
 * @export
 * @interface ImportApi
 */
export interface ImportApiInterface {
    /**
     * Check if csv- & report files of an import-run are available for download
     * @summary Check for csv- & reportfiles of an import-run
     * @param {number} importId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    checkIfFilesAreAvailableForDownload(importId: number, options?: any): AxiosPromise<Array<FileAvailabilityInfo>>;

    /**
     * Download a specified csv-file
     * @summary Download csv-file
     * @param {number} importId 
     * @param {string} filename 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    downloadCsvFile(importId: number, filename: string, options?: any): AxiosPromise<any>;

    /**
     * Download a specified file
     * @summary Download file
     * @param {string} filename 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    downloadFile(filename: string, options?: any): AxiosPromise<any>;

    /**
     * Download state report file
     * @summary Download state report file
     * @param {number} importId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    downloadIdiStateReportFile(importId: number, options?: any): AxiosPromise<any>;

    /**
     * Download the report error file of an importRun
     * @summary Download report error file
     * @param {number} importId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    downloadReportErrorFile(importId: number, options?: any): AxiosPromise<any>;

    /**
     * Download the report overview file of an importRun
     * @summary Download report overview file
     * @param {number} importId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    downloadReportOverviewFile(importId: number, options?: any): AxiosPromise<any>;

    /**
     * Returns a list of all dataset-enforcements
     * @summary Get current dataset-enforcements
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    getAllDatasetEnforcement(options?: any): AxiosPromise<DatasetEnforcement>;

    /**
     * List all files of import-folder
     * @summary List all files of import-folder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    getAllImportFolderFiles(options?: any): AxiosPromise<Array<FileAvailabilityInfo>>;

    /**
     * Returns the import run related business logs
     * @summary Get business logs for import run id
     * @param {number} importId 
     * @param {'DEBUG' | 'INFO' | 'WARN' | 'ERROR'} [excludedLevel] Log-Level, dessen Messages nicht im Ergebnis enthalten sein sollen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    getAllImportLogs(importId: number, excludedLevel?: 'DEBUG' | 'INFO' | 'WARN' | 'ERROR', options?: any): AxiosPromise<Array<ImportRunLog>>;

    /**
     * Returns a list of all imports
     * @summary Get all imports
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    getAllImports(options?: any): AxiosPromise<Array<ImportRunReport>>;

    /**
     * Returns which error codes occured and how often they occured during the import
     * @summary Get errorcodes and number of their occurances for import run id
     * @param {number} importId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    getErrorCodeCounts(importId: number, options?: any): AxiosPromise<Array<ErrorCodeAndCount>>;

    /**
     * Returns the import run report for given id
     * @summary Get the import run report details for id
     * @param {number} importId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    getImport(importId: number, options?: any): AxiosPromise<ImportRunReport>;

    /**
     * Returns the import run related business logs for the page number
     * @summary Get business logs for import run id and page
     * @param {number} importId 
     * @param {LogSearchParams} [logSearchParams] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    getImportLogs(importId: number, logSearchParams?: LogSearchParams, options?: any): AxiosPromise<Array<ImportRunLog>>;

    /**
     * Returns the number of run related business logs
     * @summary Get count of logs for import run id
     * @param {number} importId 
     * @param {LogFilter} [logFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    getImportLogsCount(importId: number, logFilter?: LogFilter, options?: any): AxiosPromise<number>;

    /**
     * Returns the Divis-IDs/IAM-UIDs of all records where messages of a specified type occurred within an import.
     * @summary Get all occurrences of a specified messagetype for one import run
     * @param {number} importId 
     * @param {string} messagetype 
     * @param {string} ucsDataContext 
     * @param {Array<'WARN' | 'ERROR'>} level Log-Level, dessen Messages im Ergebnis enthalten sein sollen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    getMessageOccurrences(importId: number, messagetype: string, ucsDataContext: string, level: Array<'WARN' | 'ERROR'>, options?: any): AxiosPromise<Array<MessageOccurrence>>;

    /**
     * null
     * @summary Set dataset-enforcements list a new import manually
     * @param {DatasetEnforcement} [datasetEnforcement] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    setDatasetEnforcements(datasetEnforcement?: DatasetEnforcement, options?: any): AxiosPromise<void>;

    /**
     * Triggers a new import manually if process is not already running
     * @summary Trigger a new import manually
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    triggerImportManually(options?: any): AxiosPromise<number>;

}

/**
 * ImportApi - object-oriented interface
 * @export
 * @class ImportApi
 * @extends {BaseAPI}
 */
export class ImportApi extends BaseAPI implements ImportApiInterface {
    /**
     * Check if csv- & report files of an import-run are available for download
     * @summary Check for csv- & reportfiles of an import-run
     * @param {number} importId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public checkIfFilesAreAvailableForDownload(importId: number, options?: any) {
        return ImportApiFp(this.configuration).checkIfFilesAreAvailableForDownload(importId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Download a specified csv-file
     * @summary Download csv-file
     * @param {number} importId 
     * @param {string} filename 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public downloadCsvFile(importId: number, filename: string, options?: any) {
        return ImportApiFp(this.configuration).downloadCsvFile(importId, filename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Download a specified file
     * @summary Download file
     * @param {string} filename 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public downloadFile(filename: string, options?: any) {
        return ImportApiFp(this.configuration).downloadFile(filename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Download state report file
     * @summary Download state report file
     * @param {number} importId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public downloadIdiStateReportFile(importId: number, options?: any) {
        return ImportApiFp(this.configuration).downloadIdiStateReportFile(importId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Download the report error file of an importRun
     * @summary Download report error file
     * @param {number} importId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public downloadReportErrorFile(importId: number, options?: any) {
        return ImportApiFp(this.configuration).downloadReportErrorFile(importId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Download the report overview file of an importRun
     * @summary Download report overview file
     * @param {number} importId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public downloadReportOverviewFile(importId: number, options?: any) {
        return ImportApiFp(this.configuration).downloadReportOverviewFile(importId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of all dataset-enforcements
     * @summary Get current dataset-enforcements
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public getAllDatasetEnforcement(options?: any) {
        return ImportApiFp(this.configuration).getAllDatasetEnforcement(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all files of import-folder
     * @summary List all files of import-folder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public getAllImportFolderFiles(options?: any) {
        return ImportApiFp(this.configuration).getAllImportFolderFiles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the import run related business logs
     * @summary Get business logs for import run id
     * @param {number} importId 
     * @param {'DEBUG' | 'INFO' | 'WARN' | 'ERROR'} [excludedLevel] Log-Level, dessen Messages nicht im Ergebnis enthalten sein sollen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public getAllImportLogs(importId: number, excludedLevel?: 'DEBUG' | 'INFO' | 'WARN' | 'ERROR', options?: any) {
        return ImportApiFp(this.configuration).getAllImportLogs(importId, excludedLevel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of all imports
     * @summary Get all imports
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public getAllImports(options?: any) {
        return ImportApiFp(this.configuration).getAllImports(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns which error codes occured and how often they occured during the import
     * @summary Get errorcodes and number of their occurances for import run id
     * @param {number} importId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public getErrorCodeCounts(importId: number, options?: any) {
        return ImportApiFp(this.configuration).getErrorCodeCounts(importId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the import run report for given id
     * @summary Get the import run report details for id
     * @param {number} importId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public getImport(importId: number, options?: any) {
        return ImportApiFp(this.configuration).getImport(importId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the import run related business logs for the page number
     * @summary Get business logs for import run id and page
     * @param {number} importId 
     * @param {LogSearchParams} [logSearchParams] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public getImportLogs(importId: number, logSearchParams?: LogSearchParams, options?: any) {
        return ImportApiFp(this.configuration).getImportLogs(importId, logSearchParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the number of run related business logs
     * @summary Get count of logs for import run id
     * @param {number} importId 
     * @param {LogFilter} [logFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public getImportLogsCount(importId: number, logFilter?: LogFilter, options?: any) {
        return ImportApiFp(this.configuration).getImportLogsCount(importId, logFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the Divis-IDs/IAM-UIDs of all records where messages of a specified type occurred within an import.
     * @summary Get all occurrences of a specified messagetype for one import run
     * @param {number} importId 
     * @param {string} messagetype 
     * @param {string} ucsDataContext 
     * @param {Array<'WARN' | 'ERROR'>} level Log-Level, dessen Messages im Ergebnis enthalten sein sollen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public getMessageOccurrences(importId: number, messagetype: string, ucsDataContext: string, level: Array<'WARN' | 'ERROR'>, options?: any) {
        return ImportApiFp(this.configuration).getMessageOccurrences(importId, messagetype, ucsDataContext, level, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * null
     * @summary Set dataset-enforcements list a new import manually
     * @param {DatasetEnforcement} [datasetEnforcement] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public setDatasetEnforcements(datasetEnforcement?: DatasetEnforcement, options?: any) {
        return ImportApiFp(this.configuration).setDatasetEnforcements(datasetEnforcement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Triggers a new import manually if process is not already running
     * @summary Trigger a new import manually
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public triggerImportManually(options?: any) {
        return ImportApiFp(this.configuration).triggerImportManually(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Redirects to frontend after login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relogin: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/relogin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Redirects to frontend after login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async relogin(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.relogin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginApiFp(configuration)
    return {
        /**
         * 
         * @summary Redirects to frontend after login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relogin(options?: any): AxiosPromise<void> {
            return localVarFp.relogin(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginApi - interface
 * @export
 * @interface LoginApi
 */
export interface LoginApiInterface {
    /**
     * 
     * @summary Redirects to frontend after login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApiInterface
     */
    relogin(options?: any): AxiosPromise<void>;

}

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI implements LoginApiInterface {
    /**
     * 
     * @summary Redirects to frontend after login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public relogin(options?: any) {
        return LoginApiFp(this.configuration).relogin(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManagementApi - axios parameter creator
 * @export
 */
export const ManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Calculates and retrieves the recordId for a given divisId
         * @summary Retrieve the recordId for a given divisId
         * @param {string} divisId given divisId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateRecordId: async (divisId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'divisId' is not null or undefined
            assertParamExists('calculateRecordId', 'divisId', divisId)
            const localVarPath = `/api/v1/management/recordId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (divisId !== undefined) {
                localVarQueryParameter['divisId'] = divisId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete all UCS classes
         * @summary Delete IAM Classes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIamClasses: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/management/iam/class`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete all UCS students who were created by idi
         * @summary Delete IAM Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIamUsers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/management/iam/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Restarts the SpringBoot context
         * @summary Restart the application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restartApplication: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/management/restart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication iamSyncBasicAuthentication required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManagementApi - functional programming interface
 * @export
 */
export const ManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * Calculates and retrieves the recordId for a given divisId
         * @summary Retrieve the recordId for a given divisId
         * @param {string} divisId given divisId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateRecordId(divisId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateRecordId(divisId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete all UCS classes
         * @summary Delete IAM Classes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIamClasses(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIamClasses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete all UCS students who were created by idi
         * @summary Delete IAM Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIamUsers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIamUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Restarts the SpringBoot context
         * @summary Restart the application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restartApplication(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restartApplication(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManagementApi - factory interface
 * @export
 */
export const ManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManagementApiFp(configuration)
    return {
        /**
         * Calculates and retrieves the recordId for a given divisId
         * @summary Retrieve the recordId for a given divisId
         * @param {string} divisId given divisId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateRecordId(divisId: string, options?: any): AxiosPromise<string> {
            return localVarFp.calculateRecordId(divisId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete all UCS classes
         * @summary Delete IAM Classes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIamClasses(options?: any): AxiosPromise<void> {
            return localVarFp.deleteIamClasses(options).then((request) => request(axios, basePath));
        },
        /**
         * Delete all UCS students who were created by idi
         * @summary Delete IAM Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIamUsers(options?: any): AxiosPromise<void> {
            return localVarFp.deleteIamUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * Restarts the SpringBoot context
         * @summary Restart the application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restartApplication(options?: any): AxiosPromise<void> {
            return localVarFp.restartApplication(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManagementApi - interface
 * @export
 * @interface ManagementApi
 */
export interface ManagementApiInterface {
    /**
     * Calculates and retrieves the recordId for a given divisId
     * @summary Retrieve the recordId for a given divisId
     * @param {string} divisId given divisId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApiInterface
     */
    calculateRecordId(divisId: string, options?: any): AxiosPromise<string>;

    /**
     * Delete all UCS classes
     * @summary Delete IAM Classes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApiInterface
     */
    deleteIamClasses(options?: any): AxiosPromise<void>;

    /**
     * Delete all UCS students who were created by idi
     * @summary Delete IAM Users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApiInterface
     */
    deleteIamUsers(options?: any): AxiosPromise<void>;

    /**
     * Restarts the SpringBoot context
     * @summary Restart the application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApiInterface
     */
    restartApplication(options?: any): AxiosPromise<void>;

}

/**
 * ManagementApi - object-oriented interface
 * @export
 * @class ManagementApi
 * @extends {BaseAPI}
 */
export class ManagementApi extends BaseAPI implements ManagementApiInterface {
    /**
     * Calculates and retrieves the recordId for a given divisId
     * @summary Retrieve the recordId for a given divisId
     * @param {string} divisId given divisId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public calculateRecordId(divisId: string, options?: any) {
        return ManagementApiFp(this.configuration).calculateRecordId(divisId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete all UCS classes
     * @summary Delete IAM Classes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public deleteIamClasses(options?: any) {
        return ManagementApiFp(this.configuration).deleteIamClasses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete all UCS students who were created by idi
     * @summary Delete IAM Users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public deleteIamUsers(options?: any) {
        return ManagementApiFp(this.configuration).deleteIamUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Restarts the SpringBoot context
     * @summary Restart the application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public restartApplication(options?: any) {
        return ManagementApiFp(this.configuration).restartApplication(options).then((request) => request(this.axios, this.basePath));
    }
}


