
import { computed, defineComponent, h } from "vue";
import { useStore } from "vuex";
import { ImportRunReport, TaskStatus } from "../../generated/api/src";
import { ref, watch } from "vue";
import ImportRunCard from "@/components/ImportRunCard.vue";
import ReloadButton from "@/components/ReloadButton.vue";
import { ElMessage, ElMessageBox, ElNotification } from "element-plus";

export default defineComponent({
  name: "ImportOverviewView",
  components: { ImportRunCard, ReloadButton },
  setup() {
    const store = useStore();
    store.dispatch("getAllImports");
    const isImportRunning = ref(false);
    const generateTestdataDisabled = ref(false);
    const exportDataset1Disabled = ref(false);
    const exportDataset2Disabled = ref(false);
    const exportDataset3Disabled = ref(false);

    const pageSize = ref(7);
    const currentPage = ref(1);
    const reports = ref([]);
    const testingEnabled = ref(false);
    testingEnabled.value = store.getters.testingEnabled;

    const ucsUsersDeletionState = ref(store.getters.ucsUsersDeletionState);
    const ucsClassesDeletionState = ref(store.getters.ucsClassesDeletionState);

    async function restartServerManuallyWithConfirmation() {
      ElMessageBox.confirm(
        "Soll IDI wirklich neu gestartet werden?",
        "Achtung!",
        {
          confirmButtonText: "Ja",
          cancelButtonText: "Nein",
          type: "warning",
        }
      )
        .then(async () => {
          ElMessage({
            type: "success",
            message: "IDI wird neu gestartet",
          });
          await store.dispatch("restartApplication");
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "IDI-Neustart wurde abgebrochen",
          });
        });
    }

    async function startManualImport() {
      await store.dispatch("triggerImportManually");
      isImportRunning.value = true;
    }

    async function startTestdataGeneration() {
      await store.dispatch("triggerTestdataGeneration");
    }

    async function startTestdataExport(step: number) {
      await store.dispatch("exportTestdata", step);
      console.log(
        `Testdaten für step ${step} exportiert und bereit zum Import`
      );
    }

    async function startDeleteTestdataUsers() {
      await store.dispatch("deleteTestdataUsers");
      console.log("Testdaten 'Benutzer' gelöscht");
    }

    async function startDeleteTestdataClasses() {
      await store.dispatch("deleteTestdataClasses");
      console.log("Testdaten 'Gruppen' gelöscht");
    }

    async function startDeleteAllTestdata() {
      startDeleteTestdataUsers();
      startDeleteTestdataClasses();
    }

    function handleCurrentChange(pageNumber: number) {
      reports.value = store.getters.reports.slice(
        (pageNumber - 1) * pageSize.value,
        pageSize.value * pageNumber
      );
    }

    watch(
      () => store.getters.testingEnabled,
      (newValue) => {
        testingEnabled.value = newValue;
      }
    );

    watch(
      () => store.getters.ucsUsersDeletionState,
      (newValue) => {
        if (ucsUsersDeletionState.value.active && !newValue.active) {
          let message = "Löschen erfolgreich. ";
          if (newValue.numberOfFailedItems > 0) {
            message =
              "Bei " +
              newValue.numberOfFailedItems +
              " Schulen sind Fehler aufgetreten. ";
          }
          if (newValue.numberOfItemsWithIgnoredSubItems > 0) {
            message +=
              "Bei " +
              newValue.numberOfItemsWithIgnoredSubItems +
              " Schulen wurden Daten ignoriert, da diese nicht von IDI angelegt wurden.";
          }
          setTimeout(() => {
            ElNotification({
              title: "Löschen von Benutzer-Daten beendet.",
              message: h("i", { style: "color: teal" }, message),
              duration: -1,
              showClose: true,
              type: newValue.numberOfFailedItems > 0 ? "error" : "success",
            });
          }, 1000);
        }
        ucsUsersDeletionState.value = newValue;
      }
    );
    watch(
      () => store.getters.ucsClassesDeletionState,
      (newValue) => {
        if (ucsClassesDeletionState.value.active && !newValue.active) {
          let message = "Löschen erfolgreich. ";
          if (newValue.numberOfFailedItems > 0) {
            message =
              "Bei " +
              newValue.numberOfFailedItems +
              " Schulen sind Fehler aufgetreten. ";
          }
          if (newValue.numberOfItemsWithIgnoredSubItems > 0) {
            message +=
              "Bei " +
              newValue.numberOfItemsWithIgnoredSubItems +
              " Schulen wurden Daten ignoriert, da diese nicht von IDI angelegt wurden.";
          }

          setTimeout(() => {
            ElNotification({
              title: "Löschen von Gruppen-Daten beendet.",
              message: h("i", { style: "color: teal" }, message),
              duration: -1,
              showClose: true,
              type: newValue.numberOfFailedItems > 0 ? "error" : "success",
            });
          }, 1000);
        }
        ucsClassesDeletionState.value = newValue;
      }
    );

    watch(
      () => store.getters.reports,
      (newReports) => {
        isImportRunning.value =
          newReports.filter(
            (report: ImportRunReport) => report.status === TaskStatus.RUNNING
          ).length > 0;
        reports.value = store.getters.reports.slice(0, pageSize.value);
        currentPage.value = 1;
      }
    );

    watch(
      () => store.getters.testdataGenerationEnabled,
      (testdataGenerationEnabled) => {
        generateTestdataDisabled.value = !testdataGenerationEnabled;
      }
    );
    watch(
      () => store.getters.dataset1ExportEnabled,
      (dataset1ExportEnabled) => {
        exportDataset1Disabled.value = !dataset1ExportEnabled;
      }
    );
    watch(
      () => store.getters.dataset2ExportEnabled,
      (dataset2ExportEnabled) => {
        exportDataset2Disabled.value = !dataset2ExportEnabled;
      }
    );
    watch(
      () => store.getters.dataset3ExportEnabled,
      (dataset3ExportEnabled) => {
        exportDataset3Disabled.value = !dataset3ExportEnabled;
      }
    );

    return {
      total: computed(() => store.getters.reports.length),
      restartServerConfirmDialog: restartServerManuallyWithConfirmation,
      startImport: startManualImport,
      generateTestdata: startTestdataGeneration,
      exportTestdata: startTestdataExport,
      deleteTestdataUsers: startDeleteTestdataUsers,
      deleteTestdataClasses: startDeleteTestdataClasses,
      deleteAllTestdata: startDeleteAllTestdata,
      handleCurrentChange,
      isImportRunning,
      currentPage,
      pageSize,
      reports,
      testingEnabled,
      generateTestdataDisabled,
      exportDataset1Disabled,
      exportDataset2Disabled,
      exportDataset3Disabled,
      ucsUsersDeletionState,
      ucsClassesDeletionState,
    };
  },
});
