import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import ImportOverviewView from "@/views/ImportOverviewView.vue";
import ImportDetailsView from "@/views/ImportDetailsView.vue";
import ImportFolderView from "@/views/ImportFolderView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "ImportOverview",
    component: ImportOverviewView,
  },
  {
    path: "/importdetails/:id",
    name: "ImportDetails",
    component: ImportDetailsView,
    props: true,
  },
  {
    path: "/importfolder",
    name: "ImportFolder",
    component: ImportFolderView,
    props: true,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
