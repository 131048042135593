<template>
  <MainMenu />
</template>

<script>
import MainMenu from "@/components/MainMenu";
import * as Stomp from "stompjs";
import * as SockJS from "sockjs-client";
import store from "./store";
import apiClient from "@/http-common";

let stompClient = null;
export default {
  name: "App",
  components: { MainMenu },
  setup() {
    const baseUrl = apiClient.defaults.baseURL.replace("api/v1", "connect");

    const stompSuccessCallback = function () {
      stompClient.subscribe("/topic/messages", async (messageOutput) => {
        await store.dispatch("setBackendMessageContent", messageOutput.body);
        console.log(messageOutput.body);
      });
    };
    const stompFailureCallback = async (error) => {
      stompClient.disconnect();
      console.error("STOMP: ", error);
      await store.dispatch("getLoginStatus");

      setTimeout(stompConnect, 10000);
      console.log("STOMP: Reconecting in 10 seconds");
    };

    function stompConnect() {
      console.log("STOMP: Attempting connection");
      const socket = new SockJS(baseUrl);
      stompClient = Stomp.over(socket);
      stompClient.connect("", "", stompSuccessCallback, stompFailureCallback);
    }

    stompConnect();
  },
};
</script>
