import axios, { AxiosInstance } from "axios";

const apiClient: AxiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_IDI_IMPORTER_BASE_URI}/api/v1`,
  headers: {
    "Content-type": "application/json",
  },
});

export default apiClient;
