import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: _ctx.changeDetectionTaskReports,
    "max-height": "400",
    stripe: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        prop: "name",
        label: "Name",
        "min-width": "180"
      }),
      _createVNode(_component_el_table_column, {
        formatter: (value) => (value ? _ctx.formatNumber(value.newCount) : ''),
        label: "Anzahl neuer Datensätze",
        "min-width": "180"
      }, null, 8, ["formatter"]),
      _createVNode(_component_el_table_column, {
        formatter: (value) => (value ? _ctx.formatNumber(value.updatedCount) : ''),
        label: "Anzahl geänderter Datensätze",
        "min-width": "180"
      }, null, 8, ["formatter"]),
      _createVNode(_component_el_table_column, {
        formatter: 
        (value) =>
          value
            ? '' +
              _ctx.formatNumber(
                value.existingNotUpdatedCount + value.existingRecheckCount
              ) +
              ' (' +
              _ctx.formatNumber(value.existingNotUpdatedCount) +
              ' / ' +
              _ctx.formatNumber(value.existingRecheckCount) +
              ')'
            : ''
      ,
        label: "An­zahl be­reits be­kannt­er Daten­sätze (zu­vor fehler­haft / Wieder­vor­lage)",
        "min-width": "180"
      }, null, 8, ["formatter"]),
      _createVNode(_component_el_table_column, {
        prop: "startedAt",
        label: "Start",
        "min-width": "100"
      }),
      _createVNode(_component_el_table_column, {
        prop: "endedAt",
        label: "Ende",
        "min-width": "100"
      }),
      _createVNode(_component_el_table_column, {
        prop: "errorCode",
        label: "Fehler Code",
        "min-width": "90"
      }),
      _createVNode(_component_el_table_column, {
        prop: "status",
        label: "Status"
      })
    ]),
    _: 1
  }, 8, ["data"]))
}