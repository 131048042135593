import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/index.css";
import ElementPlus from "element-plus";
import "./styles/index.scss";
import axios from "axios";

createApp(App).use(store).use(router).use(ElementPlus).mount("#app");

if (process.env.VUE_APP_CREDENTIALS_ENABLED === "true") {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      let shouldTryRelogin = true;
      if (
        error &&
        error.request &&
        error.request.responseURL &&
        (error.request.responseURL.endsWith("/api/v1/management/iam/class") ||
          error.request.responseURL.endsWith("/api/v1/management/iam/user") ||
          error.request.responseURL.endsWith("/file/availability"))
      ) {
        shouldTryRelogin = false;
      }

      console.log("shouldTryRelogin: ", shouldTryRelogin);
      if (shouldTryRelogin) {
        window.location.href = process.env.VUE_APP_RELOGIN_URL as string;
      }
      return error.response;
    }
  );

  axios.defaults.withCredentials = true;
}
