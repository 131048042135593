import { TaskStatus } from "../../generated/api/src";
import { TaskStatusGerman } from "@/models/TaskStatusGerman";
import moment from "moment";
import { translationMap } from "@/models/GroupsTranslation";

class ConversionService {
  translate(s: string | undefined): string {
    if (s === undefined) {
      return "";
    }
    if (translationMap.has(s)) {
      return translationMap.get(s);
    }
    return s;
  }

  translateStatus(
    taskStatus?: TaskStatus
  ): TaskStatusGerman | TaskStatus | undefined {
    return taskStatus ? TaskStatusGerman[taskStatus] : taskStatus;
  }

  formatTimeToMilliseconds(date?: string) {
    date = date ? date : "";
    moment.locale("de");
    const momentDate = moment(date);
    if (!momentDate.isValid()) {
      return date;
    }
    return momentDate.format("DD.MM.YY HH:mm:ss.SSS");
  }

  formatTime(date?: string) {
    date = date ? date : "";
    moment.locale("de");
    const momentDate = moment(date);
    if (!momentDate.isValid()) {
      return date;
    }
    return momentDate.format("DD.MM.YYYY HH:mm:ss");
  }

  formatNumber(aNumber: number): string {
    if (!Number.isInteger(aNumber)) {
      aNumber = 0;
    }
    const unformattedStringNumber = ("" + aNumber).split("").reverse();
    let formattedNumberString = "";
    let count = 1;
    for (let i = 1; i <= unformattedStringNumber.length; i++) {
      const currentChar = unformattedStringNumber[i - 1];

      formattedNumberString += currentChar;
      if (count === 3 && i !== unformattedStringNumber.length) {
        formattedNumberString += ".";
        count = 1;
      } else {
        count++;
      }
    }

    return formattedNumberString.split("").reverse().join("");
  }

  computeDuration(start?: string, end?: string) {
    if (!start || !end) {
      return "";
    }
    moment.locale("de");
    const ms = moment(end).diff(moment(start));
    const d = moment.duration(ms);
    return Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
  }
}

export default new ConversionService();
